import type { ConfigInterface } from '@/services/interfaces';

export const config: ConfigInterface = {
  helpCenterPageUrl: 'https://help.timecamp.com/help',
  kioskPageUrl: 'https://kiosk.timecamp.io/',
  kioskHelpPage: '/kiosk',
  feedbackModalTagId: '44ab48d3-027f-40dc-9d6d-ab4a302f6a1f',
  feedbackKioskBugModalTagId: '46f237bc-8d75-410c-9bda-251468f15cfe',
  feedbackKioskFeedbackModalTagId: 'b493ee01-98c1-4950-90ae-8264adaf0e79',
  feedbackAssistantModalTagId: 'aa9ec6b4-1054-472d-94ff-b91f55f34920',
  timezoneOffset: 0,
  storage: 'LocalStorage',
  colors: [
    {
      name: 'Lime',
      value: '#77C903',
    },
    {
      name: 'Emerald',
      value: '#5CCB72',
    },
    {
      name: 'Teal',
      value: '#7AD2B9',
    },
    {
      name: 'Cyan',
      value: '#50A5A6',
    },
    {
      name: 'Sky',
      value: '#4DC2E8',
    },
    {
      name: 'Azure',
      value: '#0E7FF8',
    },
    {
      name: 'Midnight',
      value: '#175799',
    },
    {
      name: 'Violet',
      value: '#5B4D99',
    },
    {
      name: 'Fuchsia',
      value: '#A12687',
    },
    {
      name: 'Rose',
      value: '#D96481',
    },
    {
      name: 'Wine',
      value: '#BE2D33',
    },
    {
      name: 'Peanut',
      value: '#8B6A49',
    },
    {
      name: 'Corn',
      value: '#E0C600',
    },
    {
      name: 'Honey',
      value: '#F6B042',
    },
    {
      name: 'Gray',
      value: '#8A8A8A',
    },
  ],
  reCaptcha: {
    enabled: false,
    siteKey: '',
  },
  ...globalThis.FRONTEND_CONFIG,
};
